
    import './styles.scoped.css';
    export default {
  "toggle": "awsui_toggle_1uo6m_1362i_193",
  "drawer-triggers": "awsui_drawer-triggers_1uo6m_1362i_199",
  "drawer": "awsui_drawer_1uo6m_1362i_199",
  "drawer-mobile": "awsui_drawer-mobile_1uo6m_1362i_209",
  "drawer-closed": "awsui_drawer-closed_1uo6m_1362i_212",
  "drawer-content": "awsui_drawer-content_1uo6m_1362i_219",
  "drawer-content-clickable": "awsui_drawer-content-clickable_1uo6m_1362i_233",
  "drawer-resize-content": "awsui_drawer-resize-content_1uo6m_1362i_243",
  "drawer-content-wrapper": "awsui_drawer-content-wrapper_1uo6m_1362i_248",
  "drawer-triggers-wrapper": "awsui_drawer-triggers-wrapper_1uo6m_1362i_252",
  "drawer-trigger": "awsui_drawer-trigger_1uo6m_1362i_199",
  "drawer-trigger-active": "awsui_drawer-trigger-active_1uo6m_1362i_271",
  "resize-handle-wrapper": "awsui_resize-handle-wrapper_1uo6m_1362i_279",
  "hide": "awsui_hide_1uo6m_1362i_289"
};
  