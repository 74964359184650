
    import './styles.scoped.css';
    export default {
  "empty-appear": "awsui_empty-appear_n4qlp_1j0l4_153",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_n4qlp_1j0l4_1",
  "root": "awsui_root_n4qlp_1j0l4_175",
  "empty": "awsui_empty_n4qlp_1j0l4_153",
  "row": "awsui_row_n4qlp_1j0l4_218",
  "divider": "awsui_divider_n4qlp_1j0l4_222",
  "row-control": "awsui_row-control_n4qlp_1j0l4_227",
  "field": "awsui_field_n4qlp_1j0l4_231",
  "additional-info": "awsui_additional-info_n4qlp_1j0l4_235",
  "add-row": "awsui_add-row_n4qlp_1j0l4_281",
  "add-button": "awsui_add-button_n4qlp_1j0l4_285",
  "remove-button-container": "awsui_remove-button-container_n4qlp_1j0l4_289",
  "remove-button-field-padding": "awsui_remove-button-field-padding_n4qlp_1j0l4_293",
  "remove-button-own-row": "awsui_remove-button-own-row_n4qlp_1j0l4_297",
  "remove-button": "awsui_remove-button_n4qlp_1j0l4_289"
};
  