import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppContext } from '../App'
import { useIsMobile } from '../hooks/useIsMobile'
import { useGet } from '../hooks/useGet'
import { getShortText, formatDateTime } from '../common/utilities'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBadgeGroup } from '../common/RadBadgeGroup'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadExternalLink } from '../common/RadExternalLink'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput2 } from '../common/RadInput2'
import { RadLink } from '../common/RadLink'
import { RadMultiselect } from '../common/RadMultiselect'
import { RadPagination } from '../common/RadPagination'
import { RadPopover } from '../common/RadPopover'
import { RadRadioGroup } from '../common/RadRadioGroup'
import { RadSelect } from '../common/RadSelect'
import { EntityModal } from '../shared/EntityModal'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function ResourceList () {
  const pageLength = 20
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { loadingCount } = useContext(AppContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [targetPopulationFilteringText, setTargetPopulationFilteringText] = useState('')
  const currentPageIndex = searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1
  const searchText = searchParams.get('search') ?? ''
  const sectorId = searchParams.get('sector') ?? ''
  const tagIds = searchParams.get('tags') ?? ''
  const resourceTypeId = searchParams.get('resource-type') ?? ''
  const priorityPopulationIds = searchParams.get('priority-populations') ?? ''
  const dashFundingProgramId = searchParams.get('dash-funding-program') ?? ''
  const sortOrder = searchParams.get('sort') ?? 'alphabetical'

  const { data: resources, count } = useGet([
    '/api/resource',
    `?search=${searchText}`,
    `&sectorId=${sectorId}`,
    `&tagIds=${tagIds ?? ''}`,
    `&resourceTypeId=${resourceTypeId}`,
    `&priorityPopulationIds=${priorityPopulationIds}`,
    `&dashFundingProgramId=${dashFundingProgramId}`,
    `&limit=${pageLength}` +
    `&offset=${(currentPageIndex - 1) * pageLength}`,
    `&sortOrder=${sortOrder}`
  ].join(''))
  const { data: resourceTypeOptions } = useGet('/api/option/type?entity=resource&all=true')
  const { data: sectorOptions } = useGet('/api/option/sector?all=true')
  const { data: tags } = useGet('/api/tag?limit=5000')
  const { data: targetPopulationOptions } = useGet(`/api/option/priority-population?search=${targetPopulationFilteringText}&limit=500`)
  const { data: typeOptions } = useGet('/api/option/type?entity=resource')
  const { data: userInfo } = useGet('/api/user/current')

  useEffect(() => {
    if (searchParams.get('resourceId') != null) {
      setSelectedEntity({ type: 'resource', id: parseInt(searchParams.get('resourceId')) })
      searchParams.delete('resourceId')
      setSearchParams(searchParams)
    }
  }, [searchParams])

  if (
    resources != null &&
    resourceTypeOptions != null &&
    sectorOptions != null &&
    tags != null &&
    targetPopulationOptions != null &&
    typeOptions != null &&
    userInfo != null
  ) {
    const tagOptions = tags.map((x) => { return { value: x.id.toString(), label: x.name } })
    const canCreate = userInfo.roles.some((x) => x.name === 'Admin') ||
      userInfo.roles.some((x) => x.permissions.some((y) => y.name === 'Resource Create'))
    const publicUser = userInfo.roles.some((x) => x.name === 'Public User') && userInfo.roles.length === 1

    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              !publicUser &&
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton disabled={!canCreate} onClick={() => navigate('/resource/create')}>Create</RadButton>
                </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
            // description={info != null && JSON.parse(info).degraded === true ? '* Oops! Our advanced search took a coffee break. We\'ve switched to classic search for now. Thanks for your patience!' : null}
          >
            Resources
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadGrid
              gridDefinition={[
                { colspan: { default: 12, xs: 6 } },
                { colspan: { default: 12, xs: 2 } },
                { colspan: { default: 12, xs: 4 } }
              ]}
            >
              <RadInput2
                initialValue={searchText}
                placeholder='Enter a search query to sort by relevance'
                type='search'
                onEnter={({ detail }) => {
                  if (detail.value !== '') {
                    searchParams.set('search', detail.value)
                  } else {
                    searchParams.delete('search')
                  }
                  if (detail.value === '') {
                    searchParams.delete('sort')
                  } else {
                    searchParams.set('sort', 'relevance')
                  }
                  searchParams.delete('page')
                  setSearchParams(searchParams)
                }}
              />
              <RadSpaceBetween direction='horizontal' size='xs'>
                <SortPopover
                  sortOrder={sortOrder}
                  loadingCount={loadingCount}
                />
                <FilterPopover
                  resourceTypeId={resourceTypeId}
                  sectorId={sectorId}
                  tagIds={tagIds}
                  priorityPopulationIds={priorityPopulationIds}
                  targetPopulationFilteringText={targetPopulationFilteringText}
                  setTargetPopulationFilteringText={setTargetPopulationFilteringText}
                  resourceTypeOptions={resourceTypeOptions}
                  sectorOptions={sectorOptions}
                  tagOptions={tagOptions}
                  targetPopulationOptions={targetPopulationOptions}
                  loadingCount={loadingCount}
                />
              </RadSpaceBetween>
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            </RadGrid>
            {
              (sectorId !== '' || tagIds !== '' || priorityPopulationIds !== '' || resourceTypeId !== '') &&
                <RadBox color='text-status-inactive'>
                  {'Filter(s): ' +
                [
                  sectorId !== '' ? `Sector: ${sectorOptions.find(x => x.value === sectorId)?.label}` : null,
                  tagIds !== '' ? `Tags: ${tagOptions.filter(x => tagIds.split('-').includes(x.value)).map(x => x.label).join(', ')}` : null,
                  priorityPopulationIds !== ''
                    ? 'Priority Populations: ' + targetPopulationOptions
                      .filter(x => priorityPopulationIds.split('-').includes(x.value)).map(x => x.label).join(', ')
                    : null,
                  resourceTypeId !== '' ? 'Type: ' + resourceTypeOptions.find(x => x.value === resourceTypeId)?.label : null
                ]
                  .filter(x => x !== null)
                  .join(', ')}
                </RadBox>
            }
            <RadCards
              cardDefinition={{
                header: item => {
                  if (isMobile) {
                    return (
                      <RadLink className='font-weight-700' fontSize='heading-s' href={`resource/${item.id}`}>
                        {item.title}
                      </RadLink>
                    )
                  } else {
                    return (
                      <RadButton
                        onClick={() => setSelectedEntity({ type: 'resource', id: item.id })}
                        className='font-weight-700 font-size-16'
                        variant='inline-link'
                      >
                        {item.title}
                      </RadButton>
                    )
                  }
                },
                sections: [
                  {
                    id: 'source',
                    content: item => {
                      switch (item.sourceId) {
                        case 0:
                          return <RadExternalLink key={1} href={item.file} />
                        case 1:
                          return <RadLink external externalIconAriaLabel='Opens in a new tab' href={item.link} target='_blank'>link</RadLink>
                        case 2:
                          return 'Text'
                        default:
                          return '-'
                      }
                    }
                  },
                  {
                    id: 'type',
                    header: 'Type',
                    content: item => item.type?.name ?? '-'
                  },
                  {
                    id: 'summary',
                    header: 'Description',
                    content: item => getShortText(item.summary) ?? '-'
                  },
                  {
                    id: 'sectors',
                    header: 'Sectors',
                    content: item =>
                      <RadBadgeGroup
                        parentKey={item.id}
                        items={item.sectors}
                        mapFunc={x => ({ key: x.id, name: x.name })}
                      />
                  },
                  {
                    id: 'updatedAt',
                    header: 'Updated',
                    content: item => formatDateTime(item.updatedAt)
                  }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={resources}
              variant='full-page'
              empty={
                <RadBox textAlign='center' color='inherit'>
                  No records meet this search criteria.
                </RadBox>
            }
            />
            <EntityModal
              selectedEntity={selectedEntity}
              setSelectedEntity={setSelectedEntity}
              closeModal={() => setSelectedEntity(null)}
            />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function SortPopover ({ sortOrder, loadingCount }) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <RadPopover
      position='bottom'
      size='small'
      triggerType='custom'
      content={
        <RadSpaceBetween size='l'>
          <RadHeader
            variant='h2'
          >
            Sort
          </RadHeader>
          <RadRadioGroup
            onChange={({ detail }) => {
              if (detail.value === 'alphabetical') {
                searchParams.delete('sort')
              } else {
                searchParams.set('sort', detail.value)
              }
              searchParams.delete('page')
              setSearchParams(searchParams)
            }}
            items={[
              { value: 'alphabetical', label: 'Alphabetical', disabled: loadingCount > 0 || searchParams.get('search') != null },
              { value: 'relevance', label: 'Relevance', disabled: loadingCount > 0 || searchParams.get('search') == null },
              { value: 'recent', label: 'Recently Updated', disabled: loadingCount > 0 || searchParams.get('search') != null }
            ]}
            value={sortOrder}
          />
        </RadSpaceBetween>
      }
    >
      <RadButton className='sort' />
    </RadPopover>
  )
}

function FilterPopover ({
  dashFundingProgramOptions,
  loadingCount,
  organizationTypeOptions,
  priorityPopulationIds,
  projectTypeOptions,
  resourceTypeId,
  resourceTypeOptions,
  sectorId,
  sectorOptions,
  setTargetPopulationFilteringText,
  tagIds,
  tagOptions,
  targetPopulationFilteringText,
  targetPopulationOptions
}) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <RadPopover
      position='bottom'
      size='large'
      triggerType='custom'
      content={
        <RadSpaceBetween size='l'>
          <RadHeader
            variant='h2'
          >
            Filter
          </RadHeader>
          <RadSpaceBetween size='xs'>
            <RadSelect
              disabled={loadingCount > 0}
              filteringType='auto'
              selectedOption={sectorOptions.find(x => x.value === sectorId) ?? sectorOptions[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('sector')
                } else {
                  searchParams.set('sector', detail.selectedOption.value)
                }
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
              options={sectorOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose a record type'
              empty='No matches found'
            />
            <RadMultiselect
              disabled={loadingCount > 0}
              filteringType='auto'
              selectedOptions={tagOptions.filter((x) => tagIds.split('-').includes(x.value))}
              onChange={({ detail }) => {
                const ids = detail.selectedOptions.map((x) => x.value).join('-')
                if (ids === '') {
                  searchParams.delete('tags')
                } else {
                  searchParams.set('tags', ids)
                }
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
              options={tagOptions}
              selectedAriaLabel='Selected'
              deselectAriaLabel={e => `Remove ${e.label}`}
              placeholder='Choose tags'
              keepOpen={false}
            />
            <RadMultiselect
              disabled={loadingCount > 0}
              filteringType='manual'
              selectedOptions={targetPopulationOptions.filter((x) => priorityPopulationIds.split('-').includes(x.value))}
              onChange={({ detail }) => {
                const ids = detail.selectedOptions.map((x) => x.value).join('-')
                if (ids === '') {
                  searchParams.delete('priority-populations')
                } else {
                  searchParams.set('priority-populations', ids)
                }
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
              onLoadItems={({ detail }) => {
                setTargetPopulationFilteringText(detail.filteringText)
              }}
              options={targetPopulationOptions}
              selectedAriaLabel='Selected'
              deselectAriaLabel={e => `Remove ${e.label}`}
              placeholder='Choose priority populations'
              keepOpen={false}
            />
            <RadSelect
              disabled={loadingCount > 0}
              selectedOption={resourceTypeOptions.find(x => x.value === resourceTypeId) ?? resourceTypeOptions[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('resource-type')
                } else {
                  searchParams.set('resource-type', detail.selectedOption.value)
                }
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
              options={resourceTypeOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadSpaceBetween>
          <RadBox float='right'>
            <RadButton
              onClick={() => {
                searchParams.delete('sector')
                searchParams.delete('tags')
                searchParams.delete('record-type')
                searchParams.delete('resource-type')
                searchParams.delete('organization-type')
                searchParams.delete('project-type')
                searchParams.delete('priority-populations')
                searchParams.delete('dash-funding-program')
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
            >
              Clear All Filters
            </RadButton>
          </RadBox>
        </RadSpaceBetween>
      }
    >
      <RadButton className='filter' />
    </RadPopover>
  )
}
