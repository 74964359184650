
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1612d_oaaqr_183",
  "spinner-rotator": "awsui_spinner-rotator_1612d_oaaqr_1",
  "size-normal": "awsui_size-normal_1612d_oaaqr_198",
  "size-big": "awsui_size-big_1612d_oaaqr_206",
  "size-large": "awsui_size-large_1612d_oaaqr_214",
  "variant-normal": "awsui_variant-normal_1612d_oaaqr_222",
  "variant-disabled": "awsui_variant-disabled_1612d_oaaqr_225",
  "variant-inverted": "awsui_variant-inverted_1612d_oaaqr_228",
  "circle": "awsui_circle_1612d_oaaqr_240",
  "circle-left": "awsui_circle-left_1612d_oaaqr_268",
  "spinner-line-left": "awsui_spinner-line-left_1612d_oaaqr_1",
  "circle-right": "awsui_circle-right_1612d_oaaqr_273",
  "spinner-line-right": "awsui_spinner-line-right_1612d_oaaqr_1"
};
  