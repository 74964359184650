
    import './styles.scoped.css';
    export default {
  "universal-toolbar": "awsui_universal-toolbar_1kzri_682ef_153",
  "disable-body-scroll": "awsui_disable-body-scroll_1kzri_682ef_174",
  "toolbar-hidden": "awsui_toolbar-hidden_1kzri_682ef_177",
  "toolbar-container": "awsui_toolbar-container_1kzri_682ef_180",
  "universal-toolbar-nav": "awsui_universal-toolbar-nav_1kzri_682ef_189",
  "universal-toolbar-breadcrumbs": "awsui_universal-toolbar-breadcrumbs_1kzri_682ef_193",
  "universal-toolbar-drawers": "awsui_universal-toolbar-drawers_1kzri_682ef_198",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_1kzri_682ef_206",
  "drawers-mobile-triggers-container": "awsui_drawers-mobile-triggers-container_1kzri_682ef_207",
  "drawers-trigger-content": "awsui_drawers-trigger-content_1kzri_682ef_250",
  "group-divider": "awsui_group-divider_1kzri_682ef_260",
  "drawers-trigger": "awsui_drawers-trigger_1kzri_682ef_250",
  "block-body-scroll": "awsui_block-body-scroll_1kzri_682ef_273"
};
  