
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_gfwv3_31y6j_193",
  "horizontal": "awsui_horizontal_gfwv3_31y6j_196",
  "vertical": "awsui_vertical_gfwv3_31y6j_202",
  "list": "awsui_list_gfwv3_31y6j_207",
  "grid": "awsui_grid_gfwv3_31y6j_226",
  "list-item": "awsui_list-item_gfwv3_31y6j_238",
  "toggle-container-inline": "awsui_toggle-container-inline_gfwv3_31y6j_245",
  "toggle": "awsui_toggle_gfwv3_31y6j_245",
  "description": "awsui_description_gfwv3_31y6j_337",
  "separator": "awsui_separator_gfwv3_31y6j_341"
};
  