
    import './styles.scoped.css';
    export default {
  "card-inner": "awsui_card-inner_p8a6i_cn0q0_161",
  "root": "awsui_root_p8a6i_cn0q0_193",
  "header-variant-full-page": "awsui_header-variant-full-page_p8a6i_cn0q0_232",
  "header-refresh": "awsui_header-refresh_p8a6i_cn0q0_232",
  "list": "awsui_list_p8a6i_cn0q0_239",
  "list-grid-1": "awsui_list-grid-1_p8a6i_cn0q0_250",
  "card": "awsui_card_p8a6i_cn0q0_161",
  "list-grid-2": "awsui_list-grid-2_p8a6i_cn0q0_253",
  "list-grid-3": "awsui_list-grid-3_p8a6i_cn0q0_256",
  "list-grid-4": "awsui_list-grid-4_p8a6i_cn0q0_259",
  "list-grid-5": "awsui_list-grid-5_p8a6i_cn0q0_262",
  "list-grid-6": "awsui_list-grid-6_p8a6i_cn0q0_265",
  "list-grid-7": "awsui_list-grid-7_p8a6i_cn0q0_268",
  "list-grid-8": "awsui_list-grid-8_p8a6i_cn0q0_271",
  "list-grid-9": "awsui_list-grid-9_p8a6i_cn0q0_274",
  "list-grid-10": "awsui_list-grid-10_p8a6i_cn0q0_277",
  "list-grid-11": "awsui_list-grid-11_p8a6i_cn0q0_280",
  "list-grid-12": "awsui_list-grid-12_p8a6i_cn0q0_283",
  "list-grid-13": "awsui_list-grid-13_p8a6i_cn0q0_286",
  "list-grid-14": "awsui_list-grid-14_p8a6i_cn0q0_289",
  "list-grid-15": "awsui_list-grid-15_p8a6i_cn0q0_292",
  "list-grid-16": "awsui_list-grid-16_p8a6i_cn0q0_295",
  "list-grid-17": "awsui_list-grid-17_p8a6i_cn0q0_298",
  "list-grid-18": "awsui_list-grid-18_p8a6i_cn0q0_301",
  "list-grid-19": "awsui_list-grid-19_p8a6i_cn0q0_304",
  "list-grid-20": "awsui_list-grid-20_p8a6i_cn0q0_307",
  "selection-control": "awsui_selection-control_p8a6i_cn0q0_311",
  "loading": "awsui_loading_p8a6i_cn0q0_321",
  "empty": "awsui_empty_p8a6i_cn0q0_322",
  "has-header": "awsui_has-header_p8a6i_cn0q0_329",
  "refresh": "awsui_refresh_p8a6i_cn0q0_332",
  "card-header": "awsui_card-header_p8a6i_cn0q0_403",
  "card-header-inner": "awsui_card-header-inner_p8a6i_cn0q0_411",
  "card-selectable": "awsui_card-selectable_p8a6i_cn0q0_415",
  "card-selected": "awsui_card-selected_p8a6i_cn0q0_418",
  "section": "awsui_section_p8a6i_cn0q0_426",
  "section-header": "awsui_section-header_p8a6i_cn0q0_434",
  "section-content": "awsui_section-content_p8a6i_cn0q0_440",
  "footer-pagination": "awsui_footer-pagination_p8a6i_cn0q0_450"
};
  