
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1fn7j_cme8a_145",
  "styled-box": "awsui_styled-box_1fn7j_cme8a_152",
  "styled-box-checked": "awsui_styled-box-checked_1fn7j_cme8a_168",
  "styled-box-indeterminate": "awsui_styled-box-indeterminate_1fn7j_cme8a_168",
  "styled-box-disabled": "awsui_styled-box-disabled_1fn7j_cme8a_172",
  "styled-box-readonly": "awsui_styled-box-readonly_1fn7j_cme8a_172",
  "styled-line": "awsui_styled-line_1fn7j_cme8a_176",
  "styled-line-disabled": "awsui_styled-line-disabled_1fn7j_cme8a_181",
  "styled-line-readonly": "awsui_styled-line-readonly_1fn7j_cme8a_184"
};
  