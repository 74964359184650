
    import './styles.scoped.css';
    export default {
  "button-dropdown": "awsui_button-dropdown_sne0l_ulg38_145",
  "full-width": "awsui_full-width_sne0l_ulg38_148",
  "items-list-container": "awsui_items-list-container_sne0l_ulg38_153",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_sne0l_ulg38_1",
  "rotate": "awsui_rotate_sne0l_ulg38_172",
  "rotate-open": "awsui_rotate-open_sne0l_ulg38_186",
  "header": "awsui_header_sne0l_ulg38_190",
  "title": "awsui_title_sne0l_ulg38_201",
  "description": "awsui_description_sne0l_ulg38_202",
  "trigger-button": "awsui_trigger-button_sne0l_ulg38_206",
  "loading": "awsui_loading_sne0l_ulg38_210",
  "split-trigger-wrapper": "awsui_split-trigger-wrapper_sne0l_ulg38_214",
  "trigger-item": "awsui_trigger-item_sne0l_ulg38_217",
  "has-no-text": "awsui_has-no-text_sne0l_ulg38_226",
  "visual-refresh": "awsui_visual-refresh_sne0l_ulg38_229",
  "variant-normal": "awsui_variant-normal_sne0l_ulg38_240",
  "split-trigger": "awsui_split-trigger_sne0l_ulg38_214",
  "dropdown-trigger": "awsui_dropdown-trigger_sne0l_ulg38_251",
  "main-action-full-width": "awsui_main-action-full-width_sne0l_ulg38_255",
  "main-action-trigger-full-width": "awsui_main-action-trigger-full-width_sne0l_ulg38_259",
  "test-utils-button-trigger": "awsui_test-utils-button-trigger_sne0l_ulg38_263"
};
  