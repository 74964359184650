
    import './styles.scoped.css';
    export default {
  "option": "awsui_option_1p2cx_4qlzt_153",
  "disabled": "awsui_disabled_1p2cx_4qlzt_190",
  "parent": "awsui_parent_1p2cx_4qlzt_193",
  "highlighted": "awsui_highlighted_1p2cx_4qlzt_196",
  "content": "awsui_content_1p2cx_4qlzt_200",
  "label-content": "awsui_label-content_1p2cx_4qlzt_207",
  "label": "awsui_label_1p2cx_4qlzt_207",
  "tag": "awsui_tag_1p2cx_4qlzt_214",
  "label-tag": "awsui_label-tag_1p2cx_4qlzt_215",
  "label-prefix": "awsui_label-prefix_1p2cx_4qlzt_225",
  "tags": "awsui_tags_1p2cx_4qlzt_238",
  "description": "awsui_description_1p2cx_4qlzt_239",
  "selected": "awsui_selected_1p2cx_4qlzt_250",
  "icon": "awsui_icon_1p2cx_4qlzt_264",
  "icon-size-big": "awsui_icon-size-big_1p2cx_4qlzt_271",
  "filtering-match-highlight": "awsui_filtering-match-highlight_1p2cx_4qlzt_275",
  "trigger-variant": "awsui_trigger-variant_1p2cx_4qlzt_290"
};
  