
    import './styles.scoped.css';
    export default {
  "icon-shake": "awsui_icon-shake_1cbgc_1n36j_153",
  "awsui-motion-shake-horizontally": "awsui_awsui-motion-shake-horizontally_1cbgc_1n36j_1",
  "container-fade-in": "awsui_container-fade-in_1cbgc_1n36j_181",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1cbgc_1n36j_1",
  "root": "awsui_root_1cbgc_1n36j_203",
  "status-error": "awsui_status-error_1cbgc_1n36j_212",
  "status-warning": "awsui_status-warning_1cbgc_1n36j_215",
  "status-success": "awsui_status-success_1cbgc_1n36j_218",
  "status-info": "awsui_status-info_1cbgc_1n36j_221",
  "status-stopped": "awsui_status-stopped_1cbgc_1n36j_224",
  "status-pending": "awsui_status-pending_1cbgc_1n36j_227",
  "status-in-progress": "awsui_status-in-progress_1cbgc_1n36j_230",
  "status-loading": "awsui_status-loading_1cbgc_1n36j_233",
  "color-override-red": "awsui_color-override-red_1cbgc_1n36j_236",
  "color-override-grey": "awsui_color-override-grey_1cbgc_1n36j_239",
  "color-override-blue": "awsui_color-override-blue_1cbgc_1n36j_242",
  "color-override-green": "awsui_color-override-green_1cbgc_1n36j_245",
  "color-override-yellow": "awsui_color-override-yellow_1cbgc_1n36j_248",
  "container": "awsui_container_1cbgc_1n36j_181",
  "display-inline": "awsui_display-inline_1cbgc_1n36j_252",
  "icon": "awsui_icon_1cbgc_1n36j_153",
  "display-inline-block": "awsui_display-inline-block_1cbgc_1n36j_260",
  "overflow-ellipsis": "awsui_overflow-ellipsis_1cbgc_1n36j_269"
};
  