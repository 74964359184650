import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadAutosuggest } from '../common/RadAutosuggest'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadIcon } from '../common/RadIcon'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { toBase64 } from '../common/utilities'

export function ResourceEditContent ({ resource, formValues, setFormValues, modal = false }) {
  const navigate = useNavigate()
  const fileInput = useRef(null)
  const [userFilterText, setUserFilterText] = useState('')

  // const { data: resource } = useGet(formValues?.id != null ? `/api/resource/${formValues.id}` : null, true)
  const { data: dataTypeOptions } = useGet('/api/option/type?entity=data_type')
  const { data: sectorOptions } = useGet('/api/option/sector')
  const { data: typeOptions } = useGet('/api/option/type?entity=resource')
  const { data: dataLegalScoreOptions } = useGet('/api/option/type?entity=data_legal_score')
  const { data: valueScoreOptions } = useGet('/api/option/type?entity=value_score')
  const { data: tags } = useGet('/api/tag')
  const sourceOptions = [
    { value: '0', label: 'File' },
    { value: '1', label: 'Link' },
    { value: '2', label: 'Text' }
  ]
  const { data: targetPopulations } = useGet('/api/target-population?limit=1000')
  const { data: useCaseOptions } = useGet('/api/option/type?entity=project_use_case')
  const { data: userOptions } = useGet(`/api/option/user?search=${userFilterText}`)
  const { data: userInfo } = useGet('/api/user/current')

  useEffect(() => {
    const defaultFormValues = {
      sourceId: 0,
      isPrivate: false,
      dataTypes: [],
      files: [],
      projects: [],
      sectors: [],
      shares: [],
      tags: [],
      targetPopulations: [],
      topics: [],
      useCases: []
    }
    setFormValues(resource != null ? { ...resource, files: [] } : defaultFormValues)
  }, [resource])

  async function saveChanges () {
    if (resource != null) { update() } else { create() }
  }

  async function cancel () {
    if (resource != null) {
      navigate(`/resource/${resource.id}`)
    } else {
      navigate('/resource')
    }
  }

  function chooseFile () {
    fileInput.current.click()
  }

  function removeFile () {
    setFormValues({ ...formValues, file: null, fileSize: null, fileData: null })
  }

  async function onFileChange (event) {
    const file = event.target.files[0]
    if (file != null) {
      const data = await toBase64(file)
      setFormValues({ ...formValues, file: file.name, fileSize: file.size, fileData: data })
    } else {
      removeFile()
    }
    event.target.value = ''
  }

  const create = usePost('/api/resource', formValues, (resp) => { navigate(`/resource?resourceId=${resp.id}`) })
  const update = usePut(`/api/resource/${resource?.id}`, formValues, (resp) => { navigate(`/resource/${resource?.id}`) })

  if (
    formValues != null &&
    dataTypeOptions != null &&
    sectorOptions != null &&
    tags != null &&
    typeOptions != null &&
    dataLegalScoreOptions != null &&
    valueScoreOptions != null &&
    targetPopulations != null &&
    useCaseOptions != null &&
    userOptions != null &&
    userInfo != null
  ) {
    const canEditPrivacy = formValues.id == null || userInfo.roles.find((x) => x.name === 'Admin') != null || userInfo.id === formValues.createdBy

    return (
      <form onSubmit={e => e.preventDefault()}>
        <input type='file' ref={fileInput} onChange={onFileChange} style={{ display: 'none' }} />
        <RadForm
          actions={
            !modal
              ? (
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
                )
              : null
          }
        >
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadFormField label='Title *' field='title'>
                  <RadInput
                    placeholder='Enter title'
                    ariaRequired
                    value={formValues.title}
                    onChange={({ detail }) => setFormValues({ ...formValues, title: detail.value })}
                    disabled={formValues.isProtected}
                  />
                </RadFormField>
                <RadFormField label='Type *' field='typeId'>
                  <RadSelect
                    selectedOption={formValues.typeId ? typeOptions.find(x => x.value === formValues.typeId?.toString()) : { value: '', label: '-' }}
                    onChange={({ detail }) => {
                      setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                    }}
                    options={typeOptions}
                    selectedAriaLabel='Selected'
                    empty='No matches found'
                  />
                </RadFormField>
                <RadCheckbox
                  onChange={({ detail }) => {
                    if (detail.checked) {
                      setFormValues({ ...formValues, legalBibliography: {} })
                    } else {
                      setFormValues({ ...formValues, legalBibliography: null })
                    }
                  }}
                  checked={formValues.legalBibliography != null}
                >
                  Legal Bibliography
                </RadCheckbox>
                {formValues.legalBibliography != null &&
                  <>
                    <RadFormField label='Legal Score *' field='legalBibliography.legalScoreId'>
                      <RadSelect
                        selectedOption={formValues.legalBibliography.legalScoreId ? dataLegalScoreOptions.find(x => x.value === formValues.legalBibliography.legalScoreId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          const legalBibliography = formValues.legalBibliography
                          legalBibliography.legalScoreId = parseInt(detail.selectedOption.value)
                          setFormValues({ ...formValues, legalBibliography })
                        }}
                        options={dataLegalScoreOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Data Score *' field='legalBibliography.dataScoreId'>
                      <RadSelect
                        selectedOption={formValues.legalBibliography.dataScoreId ? dataLegalScoreOptions.find(x => x.value === formValues.legalBibliography.dataScoreId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          const legalBibliography = formValues.legalBibliography
                          legalBibliography.dataScoreId = parseInt(detail.selectedOption.value)
                          setFormValues({ ...formValues, legalBibliography })
                        }}
                        options={dataLegalScoreOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Value Score *' field='legalBibliography.valueScoreId'>
                      <RadSelect
                        selectedOption={formValues.legalBibliography.valueScoreId ? valueScoreOptions.find(x => x.value === formValues.legalBibliography.valueScoreId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          const legalBibliography = formValues.legalBibliography
                          legalBibliography.valueScoreId = parseInt(detail.selectedOption.value)
                          setFormValues({ ...formValues, legalBibliography })
                        }}
                        options={valueScoreOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                  </>}
                <RadFormField label='Source *' field='sourceId'>
                  <RadSelect
                    selectedOption={sourceOptions.find(x => x.value === formValues.sourceId.toString())}
                    onChange={({ detail }) => {
                      setFormValues({ ...formValues, sourceId: parseInt(detail.selectedOption.value), text: null, file: null, fileSize: null })
                    }}
                    options={sourceOptions}
                    selectedAriaLabel='Selected'
                    empty='No matches found'
                  />
                </RadFormField>
                {
                  formValues.sourceId === 0 &&
                    <RadFormField field='file'>
                      {
                      formValues.file == null &&
                        <RadButton iconName='upload' onClick={chooseFile}>Choose file</RadButton>
                    }
                      {formValues.file != null &&
                        <RadBox textAlign='left'>
                          <RadBox float='left' padding={{ right: 's' }}>
                            <RadIcon name='status-positive' variant='success' />
                          </RadBox>
                          <RadBox float='left'>
                            {formValues.file.split('/').pop()}
                          </RadBox>
                          <RadBox float='right'>
                            <RadButton iconName='delete-marker' onClick={removeFile}>Remove file</RadButton>
                          </RadBox>
                        </RadBox>}
                    </RadFormField>
                }
                {
                  formValues.sourceId === 1 &&
                    <RadFormField label='Link *' field='link' stretch>
                      <RadInput
                        type='url'
                        inputMode='url'
                        placeholder='Enter link'
                        value={formValues.link}
                        onChange={({ detail }) => setFormValues({ ...formValues, link: detail.value, file: null, fileSize: null })}
                      />
                    </RadFormField>
                }
                {
                  formValues.sourceId === 2 &&
                    <RadFormField label='Text *' field='text' stretch>
                      <RadTextarea
                        rows={18}
                        placeholder='Enter text'
                        value={formValues.text}
                        onChange={({ detail }) => setFormValues({ ...formValues, text: detail.value })}
                      />
                    </RadFormField>
                }
                <RadFormField label='Description' field='summary' required stretch>
                  <RadTextarea
                    rows={6}
                    placeholder='Enter description.'
                    value={formValues.summary}
                    onChange={({ detail }) => setFormValues({ ...formValues, summary: detail.value })}
                  />
                </RadFormField>
                <RadFormField label='Publisher' field='publisher'>
                  <RadInput
                    placeholder='Enter publisher'
                    value={formValues.publisher}
                    onChange={({ detail }) => setFormValues({ ...formValues, publisher: detail.value })}
                  />
                </RadFormField>
                <RadFormField label='Publication Date' field='publicationDate'>
                  <RadInput
                    type='date'
                    onChange={({ detail }) => setFormValues({ ...formValues, publicationDate: detail.value })}
                    value={formValues.publicationDate ?? ''}
                  />
                </RadFormField>
                <RadFormField label='Author' field='author'>
                  <RadInput
                    placeholder='Enter author'
                    value={formValues.author}
                    onChange={({ detail }) => setFormValues({ ...formValues, author: detail.value })}
                  />
                </RadFormField>
              </RadSpaceBetween>
            </RadContainer>

            {/* Data Types */}
            {/* <RadContainer
          header={
            <RadHeader variant='h2'>Data Types</RadHeader>
          }
        >
          <RadAttributeEditor
            onAddButtonClick={() => {
              const dataTypes = formValues.dataTypes
              dataTypes.push({ uuid: uuidv4(), primaryEmployer: false })
              setFormValues({ ...formValues, dataTypes })
            }}
            onRemoveButtonClick={({
              detail: { itemIndex }
            }) => {
              const dataTypes = [...formValues.dataTypes]
              dataTypes.splice(itemIndex, 1)
              setFormValues({ ...formValues, dataTypes })
            }}
            items={formValues.dataTypes}
            addButtonText='Add new data type'
            definition={[
              {
                label: 'Name *',
                control: item => {
                  const filteredOptions = dataTypeOptions.filter((x) => !formValues.dataTypes.map((y) => y.dataTypeId?.toString()).includes(x.value))
                  return (
                    <RadFormField field={`dataTypes.${item.id ?? item.uuid}.dataTypeId`}>
                      <RadSelect
                        filteringType='auto'
                        selectedOption={dataTypeOptions.find(x => x.value === item.dataTypeId?.toString())}
                        onChange={({ detail }) => {
                          const dataTypes = formValues.dataTypes
                          item.dataTypeId = parseInt(detail.selectedOption.value)
                          setFormValues({ ...formValues, dataTypes })
                        }}
                        options={filteredOptions}
                        enteredTextLabel={value => value}
                        selectedAriaLabel='Selected'
                        placeholder='Choose a data type'
                        empty='No matches found'
                      />
                    </RadFormField>
                  )
                }
              }
            ]}
            removeButtonText='Remove'
            empty='No data types added to this resource.'
          />
        </RadContainer> */}

            <RadContainer
              field='sectors'
              header={
                <RadHeader variant='h2'>Sectors *</RadHeader>
              }
            >
              <RadAttributeEditor
                onAddButtonClick={() => {
                  const sectors = formValues.sectors
                  sectors.push({ uuid: uuidv4(), sector: {} })
                  setFormValues({ ...formValues, sectors })
                }}
                onRemoveButtonClick={({
                  detail: { itemIndex }
                }) => {
                  const sectors = [...formValues.sectors]
                  sectors.splice(itemIndex, 1)
                  setFormValues({ ...formValues, sectors })
                }}
                items={formValues.sectors.filter((x) => x.sector != null)}
                addButtonText='Add new sector'
                definition={[
                  {
                    label: 'Sector *',
                    control: item => {
                      const filteredOptions = sectorOptions.filter((x) => !formValues.sectors.map((y) => y.sectorId?.toString()).includes(x.value))
                      return (
                        <RadFormField field={`sector.${item.id ?? item.uuid}.sectorId`}>
                          <RadSelect
                            filteringType='auto'
                            selectedOption={sectorOptions.find(x => x.value === item.sectorId?.toString())}
                            onChange={({ detail }) => {
                              const sectors = formValues.sectors
                              const sector = formValues.sectors.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                              sector.sectorId = parseInt(detail.selectedOption.value)
                              sector.name = detail.selectedOption.label
                              setFormValues({ ...formValues, sectors })
                            }}
                            options={filteredOptions}
                            enteredTextLabel={value => value}
                            selectedAriaLabel='Selected'
                            placeholder='Choose a sector'
                            empty='No matches found'
                          />
                        </RadFormField>
                      )
                    }
                  }
                ]}
                removeButtonText='Remove'
                empty='No sectors added to this resource.'
              />
            </RadContainer>

            {/* Tags */}
            <RadContainer
              field='tags'
              header={
                <RadHeader variant='h2'>Tags</RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadAttributeEditor
                  onAddButtonClick={() => {
                    const tags = formValues.tags
                    tags.push({ uuid: uuidv4(), tag: {} })
                    setFormValues({ ...formValues, tags })
                  }}
                  onRemoveButtonClick={({
                    detail: { itemIndex }
                  }) => {
                    const tags = [...formValues.tags]
                    tags.splice(itemIndex, 1)
                    setFormValues({ ...formValues, tags })
                  }}
                  items={formValues.tags}
                  addButtonText='Add new tag'
                  definition={[
                    {
                      label: 'Tag *',
                      control: item => {
                        const tagOptions = tags.map((x) => { return { id: x.id, value: x.name } })
                        const filteredOptions = (tagOptions ?? []).filter((x) => !formValues.tags.map((y) => y.tag.name).includes(x.value))
                        return (
                          <RadFormField field={`tag.${item.id || item.uuid}.name`}>
                            <RadAutosuggest
                              options={filteredOptions}
                              value={item.tag.name ?? ''}
                              enteredTextLabel={value => `Use: "${value}"`}
                              placeholder='Enter tag'
                              onChange={({ detail }) => {
                                const tags = formValues.tags
                                const tag = formValues.tags.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                const id = tagOptions.find((x) => x.value === detail.value)?.id
                                tag.id = null
                                tag.tag.id = id
                                tag.tag.name = detail.value
                                setFormValues({ ...formValues, tags })
                              }}
                            />
                          </RadFormField>
                        )
                      }
                    }
                  ]}
                  removeButtonText='Remove'
                  empty='No tags added to this resource.'
                />
              </RadSpaceBetween>
            </RadContainer>

            <Projects formValues={formValues} setFormValues={setFormValues} />

            {/* Priority Populations - was target populations */}
            <RadContainer
              field='targetPopulations'
              header={
                <RadHeader variant='h2'>Priority Populations *</RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadAttributeEditor
                  onAddButtonClick={() => {
                    const targetPopulations = formValues.targetPopulations
                    targetPopulations.push({ uuid: uuidv4(), targetPopulation: {} })
                    setFormValues({ ...formValues, targetPopulations })
                  }}
                  onRemoveButtonClick={({
                    detail: { itemIndex }
                  }) => {
                    const targetPopulations = [...formValues.targetPopulations]
                    targetPopulations.splice(itemIndex, 1)
                    setFormValues({ ...formValues, targetPopulations })
                  }}
                  items={formValues.targetPopulations}
                  addButtonText='Add new priority population'
                  definition={[
                    {
                      label: 'Name',
                      control: item => {
                        const targetPopulationOptions = targetPopulations.map((x) => { return { value: x.id.toString(), label: x.name } })
                        const filteredOptions = (targetPopulationOptions ?? []).filter((x) => !formValues.targetPopulations.map((y) => y.name).includes(x.value))
                        return (
                          <RadFormField field={`targetPopulation.${item.id || item.uuid}.targetPopulationId`}>
                            <RadAutosuggest
                              options={filteredOptions}
                              value={item.targetPopulation.name ?? ''}
                              enteredTextLabel={value => `Use: "${value}"`}
                              placeholder='Enter priority population'
                              onChange={({ detail }) => {
                                const targetPopulations = formValues.targetPopulations
                                const targetPopulation = formValues.targetPopulations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                const selectedOption = targetPopulationOptions.find((x) => x.value === detail.value)
                                targetPopulation.id = null
                                if (selectedOption == null) {
                                  targetPopulation.targetPopulationId = null
                                  targetPopulation.targetPopulation.name = detail.value
                                } else {
                                  targetPopulation.targetPopulationId = parseInt(selectedOption.value)
                                  targetPopulation.targetPopulation.name = selectedOption.label
                                }
                                setFormValues({ ...formValues, targetPopulations })
                              }}
                            />
                          </RadFormField>
                        )
                      }
                    }
                  ]}
                  removeButtonText='Remove'
                  empty='No priority populations added to this resource.'
                />
              </RadSpaceBetween>
            </RadContainer>

            {/* Use Cases */}
            <RadContainer
              header={
                <RadHeader variant='h2'>Project Types</RadHeader>
              }
            >
              <RadAttributeEditor
                onAddButtonClick={() => {
                  const useCases = formValues.useCases
                  useCases.push({ uuid: uuidv4() })
                  setFormValues({ ...formValues, useCases })
                }}
                onRemoveButtonClick={({
                  detail: { itemIndex }
                }) => {
                  const useCases = [...formValues.useCases]
                  useCases.splice(itemIndex, 1)
                  setFormValues({ ...formValues, useCases })
                }}
                items={formValues.useCases}
                addButtonText='Add new project type'
                definition={[
                  {
                    label: 'Project Type *',
                    control: item => {
                      const useCases = formValues.useCases
                      const useCase = formValues.useCases.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                      const filteredOptions = useCaseOptions.filter((x) => !formValues.useCases.map((y) => y.useCaseId?.toString()).includes(x.value))
                      return (
                        <RadFormField field={`useCase.${item.id ?? item.uuid}.useCaseId`}>
                          <RadSelect
                            filteringType='auto'
                            selectedOption={useCaseOptions.find(x => x.value === item.useCaseId?.toString())}
                            onChange={({ detail }) => {
                              useCase.useCaseId = parseInt(detail.selectedOption.value)
                              useCase.name = detail.selectedOption.label
                              useCase.lastName = detail.selectedOption.label.split(' ')[1]
                              setFormValues({ ...formValues, useCases })
                            }}
                            options={filteredOptions}
                            enteredTextLabel={value => value}
                            selectedAriaLabel='Selected'
                            placeholder='Choose a project type'
                            empty='No matches found'
                          />
                        </RadFormField>
                      )
                    }
                  }
                ]}
                removeButtonText='Remove'
                empty='No project types added to this resource.'
              />
            </RadContainer>

            <Topics formValues={formValues} setFormValues={setFormValues} />

            {/* Visibility */}
            <RadContainer
              header={
                <RadHeader
                  variant='h2'
                  description={canEditPrivacy ? null : 'Only the creator of this resource or an administrator can make changes to its visibility settings.'}
                >
                  Visibility
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadCheckbox
                  checked={formValues.isPrivate}
                  onChange={({ detail }) => {
                    setFormValues({ ...formValues, isPrivate: detail.checked })
                  }}
                  disabled={!canEditPrivacy}
                >
                  Private
                </RadCheckbox>
                {formValues.isPrivate &&
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const shares = formValues.shares
                      shares.push({ uuid: uuidv4(), allowEdit: false, user: {} })
                      setFormValues({ ...formValues, shares })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const shares = [...formValues.shares]
                      shares.splice(itemIndex, 1)
                      setFormValues({ ...formValues, shares })
                    }}
                    items={formValues.shares}
                    addButtonText='Add new user'
                    disableAddButton={!canEditPrivacy}
                    isItemRemovable={item => canEditPrivacy}
                    definition={[
                      {
                        label: 'User *',
                        control: item => {
                          const filteredOptions = (userOptions ?? []).filter((x) => !formValues.shares.map((y) => y.userId?.toString()).includes(x.value))
                          const selectedOption = item.userId ? { value: item.userId.toString(), label: item.user.name } : null
                          return (
                            <RadFormField field={`shares.${item.id || item.uuid}.userId`}>
                              <RadSelect
                                selectedOption={selectedOption}
                                onChange={({ detail }) => {
                                  const shares = formValues.shares
                                  const user = formValues.shares.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                  user.userId = parseInt(detail.selectedOption.value)
                                  user.user.name = detail.selectedOption.label
                                  setFormValues({ ...formValues, shares })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a user'
                                filteringType='manual'
                                onLoadItems={({ detail }) => {
                                  setUserFilterText(detail.filteringText)
                                }}
                                empty={userFilterText ? 'No matches found' : null}
                                disabled={!canEditPrivacy}
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Permission *',
                        control: item => {
                          const options = [
                            { value: false, label: 'View only' },
                            { value: true, label: 'View and edit' }
                          ]
                          return (
                            <RadFormField field={`shares.${item.id ?? item.uuid}.allowEdit`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={options.find(x => x.value === item.allowEdit)}
                                onChange={({ detail }) => {
                                  const shares = formValues.shares
                                  const user = formValues.shares.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                  user.allowEdit = detail.selectedOption.value
                                  setFormValues({ ...formValues, shares })
                                }}
                                options={options}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a sector'
                                empty='No matches found'
                                disabled={!canEditPrivacy}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No users added to this resource.'
                  />}
              </RadSpaceBetween>
            </RadContainer>

          </RadSpaceBetween>
        </RadForm>
      </form>
    )
  }
}

function Projects ({ formValues, setFormValues }) {
  const [filteringText, setFilteringText] = useState('')
  const { data: projectOptions } = useGet(`/api/option/project?search=${filteringText}`)

  if (projectOptions != null) {
    return (
      <RadContainer
        header={
          <RadHeader variant='h2'>Projects</RadHeader>
        }
      >
        <RadAttributeEditor
          onAddButtonClick={() => {
            const projects = formValues.projects
            projects.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, projects })
          }}
          onRemoveButtonClick={({
            detail: { itemIndex }
          }) => {
            const projects = [...formValues.projects]
            projects.splice(itemIndex, 1)
            setFormValues({ ...formValues, projects })
          }}
          items={formValues.projects}
          addButtonText='Add new project'
          definition={[
            {
              label: 'Project *',
              control: item => {
                const filteredOptions = projectOptions.filter((x) => !formValues.projects.map((y) => y.projectId?.toString()).includes(x.value))
                return (
                  <RadFormField field={`projects.${item.id ?? item.uuid}.projectId`}>
                    <RadSelect
                      filteringType='auto'
                      selectedOption={projectOptions.find(x => x.value === item.projectId?.toString())}
                      onChange={({ detail }) => {
                        const projects = formValues.projects
                        const project = formValues.projects.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                        project.projectId = parseInt(detail.selectedOption.value)
                        project.name = detail.selectedOption.label
                        setFormValues({ ...formValues, projects })
                      }}
                      onLoadItems={({ detail }) => {
                        setFilteringText(detail.filteringText)
                      }}
                      options={filteredOptions}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a project'
                      empty='No matches found'
                    />
                  </RadFormField>
                )
              }
            }
          ]}
          removeButtonText='Remove'
          empty='No projects added to this organization.'
        />
      </RadContainer>
    )
  }
}

function Topics ({ formValues, setFormValues }) {
  const [filteringText, setFilteringText] = useState('')
  const { data: topicOptions } = useGet(`/api/option/topic?search=${filteringText}&required=true`)

  if (topicOptions != null) {
    return (
      <RadContainer
        header={
          <RadHeader variant='h2'>Include in Framework?</RadHeader>
        }
      >
        <RadAttributeEditor
          onAddButtonClick={() => {
            const topics = formValues.topics
            topics.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, topics })
          }}
          onRemoveButtonClick={({
            detail: { itemIndex }
          }) => {
            const topics = [...formValues.topics]
            topics.splice(itemIndex, 1)
            setFormValues({ ...formValues, topics })
          }}
          items={formValues.topics}
          addButtonText='Add new topic'
          definition={[
            {
              label: 'Name *',
              control: item => {
                const filteredOptions = topicOptions.filter((x) => !formValues.topics.map((y) => y.topicId?.toString()).includes(x.value))
                return (
                  <RadFormField field={`topic.${item.id ?? item.uuid}.topicId`}>
                    <RadSelect
                      filteringType='auto'
                      selectedOption={topicOptions.find(x => x.value === item.topicId?.toString())}
                      onChange={({ detail }) => {
                        const topics = formValues.topics
                        const topic = formValues.topics.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                        topic.topicId = parseInt(detail.selectedOption.value)
                        topic.name = detail.selectedOption.label
                        setFormValues({ ...formValues, topics })
                      }}
                      onLoadItems={({ detail }) => {
                        setFilteringText(detail.filteringText)
                      }}
                      options={filteredOptions.map((x) => { return { value: x.value, label: x.label } })}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a framework'
                      empty='No matches found'
                    />
                  </RadFormField>
                )
              }
            }
          ]}
          removeButtonText='Remove'
          empty='Not included in any frameworks.'
        />
      </RadContainer>
    )
  }
}
