
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_14iqq_g6civ_189",
  "fit-height": "awsui_fit-height_14iqq_g6civ_222",
  "with-side-media": "awsui_with-side-media_14iqq_g6civ_227",
  "variant-default": "awsui_variant-default_14iqq_g6civ_230",
  "variant-stacked": "awsui_variant-stacked_14iqq_g6civ_230",
  "refresh": "awsui_refresh_14iqq_g6civ_238",
  "sticky-enabled": "awsui_sticky-enabled_14iqq_g6civ_288",
  "with-stuck-sticky-header-at-bottom": "awsui_with-stuck-sticky-header-at-bottom_14iqq_g6civ_294",
  "with-top-media": "awsui_with-top-media_14iqq_g6civ_304",
  "content-wrapper": "awsui_content-wrapper_14iqq_g6civ_309",
  "content-wrapper-fit-height": "awsui_content-wrapper-fit-height_14iqq_g6civ_314",
  "media": "awsui_media_14iqq_g6civ_321",
  "media-top": "awsui_media-top_14iqq_g6civ_339",
  "media-side": "awsui_media-side_14iqq_g6civ_344",
  "header": "awsui_header_14iqq_g6civ_350",
  "header-full-page": "awsui_header-full-page_14iqq_g6civ_355",
  "header-variant-embedded": "awsui_header-variant-embedded_14iqq_g6civ_358",
  "header-sticky-enabled": "awsui_header-sticky-enabled_14iqq_g6civ_358",
  "header-with-media": "awsui_header-with-media_14iqq_g6civ_361",
  "header-sticky-disabled": "awsui_header-sticky-disabled_14iqq_g6civ_367",
  "header-stuck": "awsui_header-stuck_14iqq_g6civ_377",
  "header-variant-cards": "awsui_header-variant-cards_14iqq_g6civ_387",
  "header-dynamic-height": "awsui_header-dynamic-height_14iqq_g6civ_390",
  "with-paddings": "awsui_with-paddings_14iqq_g6civ_396",
  "with-hidden-content": "awsui_with-hidden-content_14iqq_g6civ_405",
  "header-variant-full-page": "awsui_header-variant-full-page_14iqq_g6civ_463",
  "header-cover": "awsui_header-cover_14iqq_g6civ_466",
  "content": "awsui_content_14iqq_g6civ_309",
  "content-fit-height": "awsui_content-fit-height_14iqq_g6civ_494",
  "content-inner": "awsui_content-inner_14iqq_g6civ_500",
  "with-header": "awsui_with-header_14iqq_g6civ_507",
  "footer": "awsui_footer_14iqq_g6civ_511",
  "with-divider": "awsui_with-divider_14iqq_g6civ_515"
};
  