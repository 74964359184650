
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_18582_sv1am_145",
  "child": "awsui_child_18582_sv1am_149",
  "horizontal": "awsui_horizontal_18582_sv1am_160",
  "horizontal-xxxs": "awsui_horizontal-xxxs_18582_sv1am_164",
  "horizontal-xxs": "awsui_horizontal-xxs_18582_sv1am_167",
  "horizontal-xs": "awsui_horizontal-xs_18582_sv1am_170",
  "horizontal-s": "awsui_horizontal-s_18582_sv1am_173",
  "horizontal-m": "awsui_horizontal-m_18582_sv1am_176",
  "horizontal-l": "awsui_horizontal-l_18582_sv1am_179",
  "horizontal-xl": "awsui_horizontal-xl_18582_sv1am_182",
  "horizontal-xxl": "awsui_horizontal-xxl_18582_sv1am_185",
  "vertical": "awsui_vertical_18582_sv1am_192",
  "vertical-xxxs": "awsui_vertical-xxxs_18582_sv1am_195",
  "vertical-xxs": "awsui_vertical-xxs_18582_sv1am_198",
  "vertical-xs": "awsui_vertical-xs_18582_sv1am_201",
  "vertical-s": "awsui_vertical-s_18582_sv1am_204",
  "vertical-m": "awsui_vertical-m_18582_sv1am_207",
  "vertical-l": "awsui_vertical-l_18582_sv1am_210",
  "vertical-xl": "awsui_vertical-xl_18582_sv1am_213",
  "vertical-xxl": "awsui_vertical-xxl_18582_sv1am_216",
  "align-center": "awsui_align-center_18582_sv1am_220",
  "align-start": "awsui_align-start_18582_sv1am_224",
  "align-end": "awsui_align-end_18582_sv1am_228"
};
  