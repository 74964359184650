
    import './styles.scoped.css';
    export default {
  "item-element": "awsui_item-element_93a1u_1ramd_145",
  "disabled": "awsui_disabled_93a1u_1ramd_157",
  "show-divider": "awsui_show-divider_93a1u_1ramd_164",
  "highlighted": "awsui_highlighted_93a1u_1ramd_167",
  "is-focused": "awsui_is-focused_93a1u_1ramd_182",
  "menu-item": "awsui_menu-item_93a1u_1ramd_187",
  "link-style": "awsui_link-style_93a1u_1ramd_199",
  "current-breadcrumb": "awsui_current-breadcrumb_93a1u_1ramd_235",
  "link-style-highlighted": "awsui_link-style-highlighted_93a1u_1ramd_243",
  "has-category-header": "awsui_has-category-header_93a1u_1ramd_249",
  "item-tooltip-wrapper": "awsui_item-tooltip-wrapper_93a1u_1ramd_249",
  "has-checkmark": "awsui_has-checkmark_93a1u_1ramd_249",
  "icon": "awsui_icon_93a1u_1ramd_253",
  "checkmark": "awsui_checkmark_93a1u_1ramd_257",
  "external-icon": "awsui_external-icon_93a1u_1ramd_264"
};
  