
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_vjswe_1k4qa_153",
  "button": "awsui_button_vjswe_1k4qa_157",
  "variant-normal": "awsui_variant-normal_vjswe_1k4qa_205",
  "disabled": "awsui_disabled_vjswe_1k4qa_223",
  "variant-primary": "awsui_variant-primary_vjswe_1k4qa_230",
  "variant-link": "awsui_variant-link_vjswe_1k4qa_255",
  "variant-icon": "awsui_variant-icon_vjswe_1k4qa_280",
  "variant-inline-icon": "awsui_variant-inline-icon_vjswe_1k4qa_305",
  "variant-inline-icon-pointer-target": "awsui_variant-inline-icon-pointer-target_vjswe_1k4qa_330",
  "variant-inline-link": "awsui_variant-inline-link_vjswe_1k4qa_355",
  "variant-modal-dismiss": "awsui_variant-modal-dismiss_vjswe_1k4qa_384",
  "variant-flashbar-icon": "awsui_variant-flashbar-icon_vjswe_1k4qa_409",
  "variant-breadcrumb-group": "awsui_variant-breadcrumb-group_vjswe_1k4qa_434",
  "variant-menu-trigger": "awsui_variant-menu-trigger_vjswe_1k4qa_464",
  "button-no-text": "awsui_button-no-text_vjswe_1k4qa_556",
  "button-no-wrap": "awsui_button-no-wrap_vjswe_1k4qa_560",
  "full-width": "awsui_full-width_vjswe_1k4qa_563",
  "icon-left": "awsui_icon-left_vjswe_1k4qa_585",
  "icon-right": "awsui_icon-right_vjswe_1k4qa_590",
  "icon": "awsui_icon_vjswe_1k4qa_585",
  "link": "awsui_link_vjswe_1k4qa_609",
  "disabled-with-reason": "awsui_disabled-with-reason_vjswe_1k4qa_609"
};
  