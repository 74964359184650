
    import './styles.scoped.css';
    export default {
  "error-icon-shake-wrapper": "awsui_error-icon-shake-wrapper_14mhv_11w8y_153",
  "warning-icon-shake-wrapper": "awsui_warning-icon-shake-wrapper_14mhv_11w8y_154",
  "awsui-motion-shake-horizontally": "awsui_awsui-motion-shake-horizontally_14mhv_11w8y_1",
  "error-icon-scale-wrapper": "awsui_error-icon-scale-wrapper_14mhv_11w8y_184",
  "warning-icon-scale-wrapper": "awsui_warning-icon-scale-wrapper_14mhv_11w8y_185",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_14mhv_11w8y_1",
  "warning": "awsui_warning_14mhv_11w8y_154",
  "error": "awsui_error_14mhv_11w8y_153",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_14mhv_11w8y_1",
  "root": "awsui_root_14mhv_11w8y_236",
  "label": "awsui_label_14mhv_11w8y_269",
  "info": "awsui_info_14mhv_11w8y_285",
  "description": "awsui_description_14mhv_11w8y_291",
  "constraint": "awsui_constraint_14mhv_11w8y_292",
  "hints": "awsui_hints_14mhv_11w8y_299",
  "constraint-has-validation-text": "awsui_constraint-has-validation-text_14mhv_11w8y_300",
  "secondary-control": "awsui_secondary-control_14mhv_11w8y_304",
  "controls": "awsui_controls_14mhv_11w8y_308",
  "label-hidden": "awsui_label-hidden_14mhv_11w8y_308",
  "label-wrapper": "awsui_label-wrapper_14mhv_11w8y_311",
  "control": "awsui_control_14mhv_11w8y_308",
  "error__message": "awsui_error__message_14mhv_11w8y_338",
  "warning__message": "awsui_warning__message_14mhv_11w8y_339",
  "visually-hidden": "awsui_visually-hidden_14mhv_11w8y_343"
};
  