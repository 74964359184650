
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1mabk_16xu4_145",
  "radio": "awsui_radio_1mabk_16xu4_178",
  "radio--has-description": "awsui_radio--has-description_1mabk_16xu4_186",
  "radio-control": "awsui_radio-control_1mabk_16xu4_190",
  "outline": "awsui_outline_1mabk_16xu4_198",
  "styled-circle-border": "awsui_styled-circle-border_1mabk_16xu4_220",
  "styled-circle-disabled": "awsui_styled-circle-disabled_1mabk_16xu4_224",
  "styled-circle-readonly": "awsui_styled-circle-readonly_1mabk_16xu4_224",
  "styled-circle-fill": "awsui_styled-circle-fill_1mabk_16xu4_229",
  "styled-circle-checked": "awsui_styled-circle-checked_1mabk_16xu4_245"
};
  