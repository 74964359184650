
    import './styles.scoped.css';
    export default {
  "icon": "awsui_icon_h11ix_t8ehv_189",
  "icon-flex-height": "awsui_icon-flex-height_h11ix_t8ehv_197",
  "size-small": "awsui_size-small_h11ix_t8ehv_225",
  "size-small-mapped-height": "awsui_size-small-mapped-height_h11ix_t8ehv_229",
  "size-normal": "awsui_size-normal_h11ix_t8ehv_244",
  "size-normal-mapped-height": "awsui_size-normal-mapped-height_h11ix_t8ehv_248",
  "size-medium": "awsui_size-medium_h11ix_t8ehv_263",
  "size-medium-mapped-height": "awsui_size-medium-mapped-height_h11ix_t8ehv_267",
  "size-big": "awsui_size-big_h11ix_t8ehv_282",
  "size-big-mapped-height": "awsui_size-big-mapped-height_h11ix_t8ehv_286",
  "size-large": "awsui_size-large_h11ix_t8ehv_301",
  "size-large-mapped-height": "awsui_size-large-mapped-height_h11ix_t8ehv_305",
  "variant-normal": "awsui_variant-normal_h11ix_t8ehv_320",
  "variant-disabled": "awsui_variant-disabled_h11ix_t8ehv_323",
  "variant-inverted": "awsui_variant-inverted_h11ix_t8ehv_326",
  "variant-subtle": "awsui_variant-subtle_h11ix_t8ehv_329",
  "variant-warning": "awsui_variant-warning_h11ix_t8ehv_332",
  "variant-error": "awsui_variant-error_h11ix_t8ehv_335",
  "variant-success": "awsui_variant-success_h11ix_t8ehv_338",
  "variant-link": "awsui_variant-link_h11ix_t8ehv_341",
  "name-angle-left-double": "awsui_name-angle-left-double_h11ix_t8ehv_344",
  "name-angle-left": "awsui_name-angle-left_h11ix_t8ehv_344",
  "name-angle-right-double": "awsui_name-angle-right-double_h11ix_t8ehv_346",
  "name-angle-right": "awsui_name-angle-right_h11ix_t8ehv_346",
  "name-arrow-left": "awsui_name-arrow-left_h11ix_t8ehv_348",
  "name-arrow-right": "awsui_name-arrow-right_h11ix_t8ehv_349",
  "name-caret-left-filled": "awsui_name-caret-left-filled_h11ix_t8ehv_350",
  "name-caret-right-filled": "awsui_name-caret-right-filled_h11ix_t8ehv_351",
  "name-audio-full": "awsui_name-audio-full_h11ix_t8ehv_352",
  "name-audio-half": "awsui_name-audio-half_h11ix_t8ehv_353",
  "name-audio-off": "awsui_name-audio-off_h11ix_t8ehv_354",
  "name-external": "awsui_name-external_h11ix_t8ehv_355",
  "name-redo": "awsui_name-redo_h11ix_t8ehv_356",
  "name-resize-area": "awsui_name-resize-area_h11ix_t8ehv_357",
  "name-send": "awsui_name-send_h11ix_t8ehv_358",
  "name-shrink": "awsui_name-shrink_h11ix_t8ehv_359",
  "name-undo": "awsui_name-undo_h11ix_t8ehv_360",
  "name-view-vertical": "awsui_name-view-vertical_h11ix_t8ehv_361",
  "badge": "awsui_badge_h11ix_t8ehv_385"
};
  