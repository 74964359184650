
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_lm6vo_24glf_153",
  "root-no-scroll": "awsui_root-no-scroll_lm6vo_24glf_160",
  "layout": "awsui_layout_lm6vo_24glf_165",
  "layout-no-scroll": "awsui_layout-no-scroll_lm6vo_24glf_171",
  "layout-main": "awsui_layout-main_lm6vo_24glf_175",
  "layout-main-scrollable": "awsui_layout-main-scrollable_lm6vo_24glf_181",
  "unfocusable": "awsui_unfocusable_lm6vo_24glf_186",
  "breadcrumbs-desktop": "awsui_breadcrumbs-desktop_lm6vo_24glf_190",
  "content-header-wrapper": "awsui_content-header-wrapper_lm6vo_24glf_195",
  "content-wrapper": "awsui_content-wrapper_lm6vo_24glf_199",
  "content-overlapped": "awsui_content-overlapped_lm6vo_24glf_203",
  "content-extra-top-padding": "awsui_content-extra-top-padding_lm6vo_24glf_207"
};
  