
    import './styles.scoped.css';
    export default {
  "button": "awsui_button_m5h9f_3ie1j_145",
  "expanded": "awsui_expanded_m5h9f_3ie1j_197",
  "offset-right-none": "awsui_offset-right-none_m5h9f_3ie1j_208",
  "offset-right-l": "awsui_offset-right-l_m5h9f_3ie1j_211",
  "offset-right-xxl": "awsui_offset-right-xxl_m5h9f_3ie1j_214",
  "text": "awsui_text_m5h9f_3ie1j_239",
  "icon": "awsui_icon_m5h9f_3ie1j_243"
};
  